import React from "react"
import styled from "styled-components"
import MuiDialogContentText from "@material-ui/core/DialogContentText"
import { sizing, spacing } from "@material-ui/system"

const DialogContentText = styled(
  ({
    width,
    height,
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    px,
    py,
    pt,
    pb,
    ...props
  }) => <MuiDialogContentText {...props} />
)`
  ${spacing}
  ${sizing}
`
DialogContentText.defaultProps = {
  px: { xs: 2, sm: 4 },
  pt: { xs: 2.5, sm: 4 },
  pb: { xs: 1.25, sm: 2 },
}
export default DialogContentText
