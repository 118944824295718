import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import CssBaseline from "@material-ui/core/CssBaseline"

import Header from "@components/Header"
import Box from "@lib/ui/core/Box"
import Footer from "@components/Footer"
import SEO from "@components/SEO"

export const GlobalStyle = createGlobalStyle`
  :focus {
    outline: none;
  }
  body {
    overflow-y: overlay;
    padding-right: 0px !important;
  }
`

const Layout = ({
  homepage,
  children,
  bgcolor,
  setMode,
  isQuiz,
  hideFooter,
}) => {
  const disabledContextMenu = (e) => {
    e.preventDefault()
  }
  return (
    <Box
      display="flex"
      minHeight="100vh"
      flexDirection="column"
      style={{
        scrollBehavior: "smooth",
      }}
      onContextMenu={disabledContextMenu}
    >
      <CssBaseline />
      <GlobalStyle />
      <SEO />
      <Header homepage={homepage} setMode={setMode} isQuiz={isQuiz} />
      <Box flexGrow={1} bgcolor={bgcolor}>
        <main>{children}</main>
      </Box>
      {!hideFooter && (
        <Box>
          <Footer />
        </Box>
      )}
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
