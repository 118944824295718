import React from "react"

import HomepageHeader from "./HomepageHeader"
import UserHeader from "./UserHeader"

const Header = ({ homepage, setMode, isQuiz }) => {
  return homepage ? (
    <HomepageHeader />
  ) : (
    <UserHeader setMode={setMode} isQuiz={isQuiz} />
  )
}

export default Header
