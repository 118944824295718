import React from "react"
import CloseIcon from "@material-ui/icons/Close"
import MuiDialogTitle from "../DialogTitle"
import MuiDialogContent from "../DialogContent"
import MuiDialogActions from "../DialogActions"
import Box from "../Box"
import Dialog from "../Dialog"
import IconButton from "../IconButton"
import SvgIcon from "../SvgIcon"
import Typography from "../Typography"

// eslint-disable-next-line prefer-arrow-callback
const DialogWithClose = React.forwardRef(function DialogWithClose(props, ref) {
  const {
    closeIcon: IconComponent,
    title,
    tips,
    subTitle,
    onClose,
    noCloseButton,
    children,
    ...rest
  } = props

  return (
    <Box>
      <Dialog onClose={onClose} ref={ref} {...rest}>
        <MuiDialogTitle disableTypography>
          <Box p={1} color="#4D4D4D">
            {title && (
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="h4" color="primary" noWrap>
                    {title}
                  </Typography>
                </Box>
                {tips && (
                  <Box p={1} ml={5} color="#4D4D4D">
                    <Typography
                      variant="footnote"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {tips}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {subTitle && subTitle}
          </Box>
          {!noCloseButton && (
            <Box position="absolute" right={4} top={4}>
              <IconButton aria-label="close" onClick={onClose}>
                {IconComponent ? (
                  <SvgIcon component={IconComponent} />
                ) : (
                  <SvgIcon component={CloseIcon} />
                )}
              </IconButton>
            </Box>
          )}
        </MuiDialogTitle>
        <MuiDialogContent>{children}</MuiDialogContent>
      </Dialog>
    </Box>
  )
})

DialogWithClose.defaultProps = {
  minWidth: { sm: "500px" },
  width: { xs: "100%", sm: "100%" },
}

export default DialogWithClose
