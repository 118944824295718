import React, { useState, useEffect, useRef } from "react"
import { withStyles } from "@material-ui/core/styles"
import { useStoreState, useStoreActions } from "easy-peasy"
import { navigate } from "@reach/router"
import Menu from "material-ui-popup-state/HoverMenu"
import { ReactComponent as tokenIcon } from "@assets/icons/token.svg"
import {
  usePopupState,
  bindHover,
  bindMenu,
  bindTrigger,
} from "material-ui-popup-state/hooks"
import Imgix from "react-imgix"
import styled from "styled-components"
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  SvgIcon,
  MenuItem,
  Paper,
  Container,
} from "@lib/ui/core"
import Link from "@components/Link"
import FirstFillProfile from "@components/FirstFillProfile"
import Logo from "@assets/images/mathodlogo.png"
import VerifyEmail from "@components/VerifyEmail"
import { getProfile } from "@services/user"

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
  margin: 0 8px;
`

// eslint-disable-next-line arrow-body-style
const UserHeader = ({ setMode, isQuiz }) => {
  const [showFillProfile, setShowFillProfile] = useState(false)
  const [showEmailParams, setShowEmailParams] = useState(false)
  const [name, setName] = useState("")

  const [anchorEl, setAnchorEl] = useState(null)

  const profile = useStoreState(({ user }) => user.profile)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(false)
  }

  const updateReduxProfile = useStoreActions(
    ({ user }) => user.updateReduxProfile
  )
  const deleteAccessToken = useStoreActions(
    ({ localStorage }) => localStorage.auth.deleteAccessToken
  )

  const fetchProfile = async () => {
    const response = await getProfile()

    if (response.item && response.item.name) {
      updateReduxProfile(response.item)
      setName(response.item.name)
      if (!response.item.email_verified_at) {
        setShowEmailParams(true)
      } else {
        setShowEmailParams(false)
        if (!response.item.school) {
          setShowFillProfile(true)
        } else {
          setShowFillProfile(false)
        }
      }
    }
  }
  useEffect(() => {
    fetchProfile()
  }, [])

  const popupState = usePopupState({ variant: "popover", popupId: "userMenu" })

  const directToOther = (url) => {
    let confirm = true
    if (isQuiz) {
      confirm = window.confirm("要離開網站嗎?\n系統可能不會儲存您所做的變更。")
    }
    if (confirm) {
      navigate(url)
    }
  }

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: "#080c33",
      borderRadius: "0",
    },
  })((props) => (
    <Menu
      open={Boolean(anchorEl)}
      keepMounted
      anchorEl={anchorEl}
      onClose={handleClose}
      {...bindMenu(popupState)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      {...props}
    />
  ))

  return (
    <AppBar bgcolor="background.header" position="sticky" elevation={12}>
      <Toolbar py={1.5}>
        <Container>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            position="relative"
          >
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={() => setMode && setMode(null)}
            >
              <a
                onClick={() => {
                  directToOther("/exercise/select")
                }}
              >
                <Box fontSize="1.75rem" height="1.75em" width="auto" clone>
                  <Imgix src={Logo} />
                </Box>
              </a>
            </Box>
            <Box mx={{ xs: 5, lg: 10 }} display="flex">
              <Box mr={5}>
                <Link to="/token">
                  <Box fontSize="1rem" color="#fff" whiteSpace="noWrap">
                    購買代幣
                  </Box>
                </Link>
              </Box>
              <Box>
                <Link to="/resources">
                  <Box fontSize="1rem" color="#fff" whiteSpace="noWrap">
                    資源下載
                  </Box>
                </Link>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {profile && (
              <Box mr={2} display="flex" alignItems="center">
                <Box mr={1} display="flex" alignItems="center">
                  <SvgIcon component={tokenIcon} />
                </Box>
                <Typography color="textSecondary">
                  x{profile.coin_balance}
                </Typography>
              </Box>
              )}
              <Box
                display="flex"
                alignItems="center"
                onClick={handleClick}
                {...bindHover(popupState)}
                {...bindTrigger(popupState)}
              >
                <Typography color="textSecondary">
                  {profile ? profile.name : ""}
                </Typography>
                <Triangle />
              </Box>
              <StyledMenu
                open={Boolean(anchorEl)}
                keepMounted
                anchorEl={anchorEl}
                onClose={handleClose}
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MenuItem
                  onClick={() => {
                    directToOther("/user")
                  }}
                >
                  <Box color="#fff">用戶中心</Box>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    let confirm = true
                    if (isQuiz) {
                      confirm = window.confirm(
                        "要離開網站嗎?\n系統可能不會儲存您所做的變更。"
                      )
                    }
                    if (confirm) {
                      deleteAccessToken()
                      navigate("/")
                    }
                  }}
                >
                  <Box color="#fff">登出</Box>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box>
        </Container>
      </Toolbar>
      <VerifyEmail open={showEmailParams} />
      <FirstFillProfile open={showFillProfile} fetchProfile={fetchProfile} />
    </AppBar>
  )
}

export default UserHeader
