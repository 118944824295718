import axios from "axios"
import urljoin from "url-join"
import store from "@store"
import { refresh } from "./auth"

const apiBaseUrl = "https://api.mathod.info/api/v1"

export const getProfile = async () => {
  const url = urljoin(apiBaseUrl, "/user")

  const { accessToken } = store.getState().localStorage.auth

  const params = {}

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.get(url, { params, ...options })
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.get(url, { params, ...newOptions })
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const updateProfile = async (payload) => {
  const url = urljoin(apiBaseUrl, "/user")

  const { accessToken } = store.getState().localStorage.auth

  const params = {
    ...payload,
  }

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.post(url, params, newOptions)
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const unlockUpload = async (payload) => {
  const url = urljoin(apiBaseUrl, "/quiz/unlock_upload")

  const { accessToken } = store.getState().localStorage.auth

  const params = {
    ...payload,
  }

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.post(url, params, newOptions)
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const uploadFile = async (payload) => {
  const url = urljoin(apiBaseUrl, "/quiz/upload")

  const { accessToken } = store.getState().localStorage.auth

  const params = new FormData()
  params.append("file", payload.file)

  params.append("quiz_id", payload.quiz_id)

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.post(url, params, newOptions)
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const getAttachments = async (payload) => {
  const { accessToken } = store.getState().localStorage.auth

  const url = urljoin(apiBaseUrl, "/quiz/attachments")

  const params = {
    ...payload,
  }

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }
  try {
    const response = await axios.get(url, { params, ...options })
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.post(url, params, newOptions)
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const updateOnboard = async () => {
  const url = urljoin(apiBaseUrl, "/user/onboard")

  const { accessToken } = store.getState().localStorage.auth

  const params = {}

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.post(url, params, newOptions)
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}
