import React from "react"
import styled from "styled-components"
import MuiCardActions from "@material-ui/core/CardActions"

const CardActions = styled(({ ...props }) => <MuiCardActions {...props} />)`
  justify-content: ${(props) => props.justify};
`

CardActions.defaultProps = {
  justify: "center",
}

export default CardActions
