import React from "react"
import MuiCheckbox from "@material-ui/core/Checkbox"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    color: "grey",
  },
}))

const Checkbox = ({ ...props }) => {
  const classes = useStyles()
  return <MuiCheckbox className={classes.root} {...props} />
}

export default Checkbox
