import React from "react"
import styled from "styled-components"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const size = "8px"

const DotContainer = styled.div`
  bottom: 0;
  text-align: right;

  &.slick-dots li {
    width: ${size};
    height: ${size};
    margin: 0 ${size};
  }

  &.slick-dots li:last-child {
    margin-right: 0;
  }

  &.slick-dots li button {
    width: ${size};
    height: ${size};
  }

  &.slick-dots li button:before {
    content: "";
    opacity: 1;
    background-color: ${({ theme }) => `${theme.palette.common.white}`};
    width: ${size};
    height: ${size};
    transition: ${({ theme }) =>
      theme.transitions.create(["background-color", "opacity"])};
    border-radius: 999em;
  }

  &.slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: ${({ theme }) => `${theme.palette.primary.main}`};
  }
`

const DotList = styled.ul`
  display: inline-block;
  margin: 0;
  padding-left: 0;
  padding-right: 1rem;
  padding-bottom: 1rem;
`

const Carousel = Slider

Carousel.defaultProps = {
  dots: true,
  infinite: true,
  speed: 300,
  autoplaySpeed: 5000,
  autoplay: true,
  pauseOnHover: false,
  arrows: false,
  // prevArrow: <PreArrow />,
  // nextArrow: <NextArrow />,
  appendDots: (dots) => (
    <DotContainer>
      <DotList>{dots}</DotList>
    </DotContainer>
  ),
}

export default Carousel
