import React from "react"
import styled from "styled-components"
import MuiSvgIcon from "@material-ui/core/SvgIcon"
import { palette, typography } from "@material-ui/system"

const SvgIcon = styled(({ ...props }) => <MuiSvgIcon {...props} />)`
  ${palette}
  ${typography}
`

export default SvgIcon
