import React, { useEffect, useState } from "react"
import {
  Box,
  DialogWithCloseButton,
  TextField,
  Button,
  Typography,
  SvgIcon,
  MenuItem,
  Hidden,
} from "@lib/ui/core"

import { useForm, Controller } from "react-hook-form"

import { ReactComponent as BoyIcon } from "@assets/images/boy.svg"
import { ReactComponent as GirlIcon } from "@assets/images/girl.svg"
// import Menu from "../Menu"

import { updateProfile } from "@services/user"

// eslint-disable-next-line arrow-body-style

const FirstFillProfile = ({
  open,
  fetchProfile,
  isChange,
  handleClose,
  record,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    setValue,
  } = useForm()
  const [gender, setGender] = useState(!isChange ? "M" : record.gender)

  const onSubmit = async (values) => {
    console.log(values)
    const result = await updateProfile({
      ...values,
      gender: gender,
    })
    if (!result.error) {
      await fetchProfile()
    }
  }

  useEffect(() => {
    if (isChange && open) {
      setTimeout(() => {
        setValue("name", record.name)
        setValue("phone", record.phone)
        setValue("dob", record.dob)
        setValue("school", record.school)
        setValue("school_grade", record.school_grade)
      })
    }
  }, [open])

  return (
    <Box>
      <DialogWithCloseButton
        noCloseButton={!isChange}
        onClose={handleClose}
        open={open}
        title={!isChange ? "認證成功" : "更改資料"}
        subTitle={!isChange ? "填寫以下資料以完成註冊" : null}
        onExited={() => {
          clearErrors()
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <Box display={!isChange ? "flex" : "none"} alignItems="center">
              <Box pr={5}>
                <Typography>性別:</Typography>
              </Box>
              <Box
                borderRadius={16}
                bgcolor={gender === "M" ? "#70B5FE" : "#fff"}
                py={0.5}
                px={5}
                mr={3}
                display="flex"
                alignItems="center"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setGender("M")}
              >
                <SvgIcon component={BoyIcon} />
              </Box>
              <Box
                borderRadius={16}
                bgcolor={gender === "F" ? "#F6AFDC" : "#fff"}
                py={0.5}
                px={5}
                display="flex"
                alignItems="center"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setGender("F")}
              >
                <SvgIcon component={GirlIcon} />
              </Box>
            </Box>
          </Box>
          <Box p={1}>
            <TextField
              error={errors.name}
              inputRef={register({
                required: "請輸入暱稱",
                minLength: {
                  value: 3,
                  message: "最少三個字元",
                },
              })}
              name="name"
              label="暱稱"
              helperText={errors.name?.message}
            />
          </Box>
          <Box p={1}>
            <TextField
              type="tel"
              error={errors.phone}
              inputRef={register({
                required: "請輸入電話號碼",
                pattern: {
                  value: /^1[0-9]{10}$|^[23569][0-9]{7}$/,
                  message: "請輸入正確的電話號碼",
                },
              })}
              name="phone"
              label="電話號碼"
              helperText={errors.phone?.message}
            />
          </Box>
          <Box p={1} display={!isChange ? "block" : "none"}>
            <TextField
              // type="date"
              // format="DD/MM/yyyy"
              disableToolbar
              disableFuture
              name="dob"
              error={errors.dob}
              inputRef={register({
                required: "請輸入出生日期",
                pattern: {
                  value: /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                  message:
                    "請輸入正確的日期格式 (YYYY-MM-DD) 例子： 1980-01-02",
                },
              })}
              placeholder="YYYY-MM-DD"
              InputLabelProps={{
                shrink: true,
              }}
              label="出生日期"
              helperText={errors.dob?.message}
            />
          </Box>
          <Box p={1}>
            <TextField
              error={errors.school}
              inputRef={register({
                required: "請輸入就讀院校",
              })}
              name="school"
              label="就讀院校"
              helperText={errors.school?.message}
            />
          </Box>
          <Box p={1}>
            <Controller
              control={control}
              name="school_grade"
              defaultValue="F.1"
              as={
                <TextField select label="年級">
                  <MenuItem value="F.1">F.1</MenuItem>
                  <MenuItem value="F.2">F.2</MenuItem>
                  <MenuItem value="F.3">F.3</MenuItem>
                  <MenuItem value="F.4">F.4</MenuItem>
                  <MenuItem value="F.5">F.5</MenuItem>
                  <MenuItem value="F.6">F.6</MenuItem>
                </TextField>
              }
            />
          </Box>
          <Box p={1} display="flex" justifyContent="flex-end">
            <Box>
              <Button type="submit">
                <Typography color="textSecondary">遞交</Typography>
              </Button>
            </Box>
          </Box>
        </form>
      </DialogWithCloseButton>
    </Box>
  )
}

export default FirstFillProfile
