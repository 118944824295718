import axios from "axios"
import urljoin from "url-join"
import store from "@store"
import { navigate } from "@reach/router"

const apiBaseUrl = "https://api.mathod.info/api/v1"

export const login = async (payload) => {
  const url = urljoin(apiBaseUrl, "/auth/login")
  const params = {
    ...payload,
  }

  try {
    const response = await axios.post(url, params)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const signup = async (payload) => {
  const url = urljoin(apiBaseUrl, "/auth/signup")
  const params = {
    ...payload,
  }

  try {
    const response = await axios.post(url, params)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const logout = async () => {
  const accessToken = ""
  const url = urljoin(apiBaseUrl, "/auth/logout")
  const params = {}
  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const forgetPassword = async (payload) => {
  const url = urljoin(apiBaseUrl, "/auth/forget_password")
  const params = {
    ...payload,
  }

  try {
    const response = await axios.post(url, params)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const refresh = async (accessToken) => {
  const {
    deleteAccessToken,
    updateAccessToken,
  } = store.getActions().localStorage.auth
  const url = urljoin(apiBaseUrl, "/auth/refresh_token")
  const params = {}

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    if (response.data.auth.access_token) {
      updateAccessToken(response.data.auth.access_token)
    }
    return response
  } catch (e) {
    deleteAccessToken()
    navigate("/")
  }
  return false
}

export const googleLogin = async (payload) => {
  const url = urljoin(apiBaseUrl, "/auth/google_callback")
  const params = {
    ...payload,
  }

  try {
    const response = await axios.post(url, params)
    return response.data
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const facebookLogin = async (payload) => {
  const url = urljoin(apiBaseUrl, "/auth/facebook_callback")
  const params = {
    ...payload,
  }

  try {
    const response = await axios.post(url, params)
    return response.data
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const updatePW = async (payload) => {
  const url = urljoin(apiBaseUrl, "/auth/change_password")

  const { accessToken } = store.getState().localStorage.auth

  const params = {
    ...payload,
  }

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.post(url, params, newOptions)
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const resendVerify = async () => {
  const url = urljoin(apiBaseUrl, "/auth/resend_verify_email")

  const { accessToken } = store.getState().localStorage.auth

  const params = {}

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.post(url, params, newOptions)
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}
