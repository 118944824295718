import MuiCardHeader from "@material-ui/core/CardHeader"
import styled from "styled-components"
import React from "react"

const CardHeader = styled(({ ...props }) => <MuiCardHeader {...props} />)`
  justify-content: ${(props) => props.justify};
`

CardHeader.defaultProps = {
  justify: "center",
}

export default CardHeader
