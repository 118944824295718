import React from "react"
import Alert from "../Alert"
import Snackbar from "../Snackbar"

const ErrorAlert = ({ open, handleClose, children }) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="error">
      {children}
    </Alert>
  </Snackbar>
)

export default ErrorAlert
