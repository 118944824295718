import React, { useState } from "react"
import { useStoreActions } from "easy-peasy"
import { navigate } from "@reach/router"
import { Box, DialogWithCloseButton, Typography, Button } from "@lib/ui/core"
import Logo from "@assets/images/email.png"
import Imgix from "react-imgix"
// eslint-disable-next-line arrow-body-style

import { resendVerify } from "@services/auth"

import Spinner from "@components/Spinner"

const VerifyEmail = ({ open }) => {
  const [resent, setResent] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleSendEmail = async () => {
    setLoading(true)
    await resendVerify()
    setResent(true)
    setLoading(false)
  }

  const deleteAccessToken = useStoreActions(
    ({ localStorage }) => localStorage.auth.deleteAccessToken
  )

  const logout = () => {
    deleteAccessToken()
    navigate("/")
  }

  return (
    <Box>
      <DialogWithCloseButton noCloseButton onClose={() => {}} open={open}>
        <Box px={10}>
          <Box display="flex" justifyContent="center" py={2}>
            <Imgix src={Logo} width={350} height={215} />
          </Box>
          <Box color="#007bff" display="flex" justifyContent="center" py={2}>
            <Typography variant="h4">你已成功註冊!</Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-start"
            py={2}
            flexWrap="wrap"
          >
            <Typography variant="body2">
              激活郵件已發送到你的電郵，請點擊郵件內的連結以
            </Typography>
            <Typography variant="body2">完成電郵認證。</Typography>
            {!resent ? (
              <Box
                pl={0.5}
                color="#007BFF"
                style={{ cursor: "pointer" }}
                display="flex"
                onClick={() => {
                  if (!loading) handleSendEmail()
                }}
              >
                <Typography variant="footnote">
                  沒有收到郵件? 點擊重發
                </Typography>
                {loading && (
                  <Box ml={0.5}>
                    <Spinner size={12} />
                  </Box>
                )}
              </Box>
            ) : (
              <Box pl={0.5} color="#007BFF">
                <Typography variant="footnote">郵件已重發</Typography>
              </Box>
            )}
            <Box my={1}>
              <Typography variant="body2">
                如未在收件箱找到郵件，請查看郵件收件箱中的其他位置如「促銷內容」和「垃圾郵件」
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button onClick={logout}>
              <Typography color="textSecondary">登出</Typography>
            </Button>
          </Box>
        </Box>
      </DialogWithCloseButton>
    </Box>
  )
}

export default VerifyEmail
