import MuiAvatar from "@material-ui/core/Avatar"
import { height } from "@material-ui/system"
import React from "react"
import styled from "styled-components"

const Avatar = styled(({ ...props }) => <MuiAvatar {...props} />)`
  background-color: ${(props) => props.bgcolor};
  height: 100px;
  width: 100px;
`

export default Avatar
