import React from "react"
import { makeStyles } from "@material-ui/core"
import Fab from "../Fab"
import Box from "../Box"
import SvgIcon from "../SvgIcon"
const useStyles = makeStyles((theme) => ({
  img: {
    marginRight: theme.spacing(1),
    marginTop: "auto",
    marginBottom: "auto",
  },
  fab: {
    marginBottom: theme.spacing(1),
  },
}))

// eslint-disable-next-line prefer-arrow-callback
const SocialFab = React.forwardRef(function DialogWithClose(props, ref) {
  const { icon, fontSize, children, ...rest } = props
  const classes = useStyles()
  return (
    <Fab ref={ref} {...rest} className={classes.fab}>
      {icon && <SvgIcon component={icon} fontSize={fontSize} />}
      {children}
    </Fab>
  )
})

export default SocialFab
