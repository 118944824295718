import React from "react"
import MuiFab from "@material-ui/core/Fab"
import styled from "styled-components"
import { sizing, spacing, borders } from "@material-ui/system"

const Fab = styled(
  ({ width, maxWidth, minWidth, bgcolor, px, py, borderRadius, ...props }) => (
    <MuiFab {...props} />
  )
)`
  ${spacing}
  ${borders}
  ${sizing}
`

Fab.defaultProps = {
  px: "3rem",
  py: "0.5rem",

  variant: "extended",
}

export default Fab
