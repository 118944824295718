import React, { useState } from "react"
import { useStoreActions } from "easy-peasy"
import { navigate } from "@reach/router"
import { useGoogleLogin } from "react-google-login"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import {
  Box,
  DialogWithCloseButton,
  TextField,
  Button,
  Checkbox,
  SvgIcon,
  Typography,
  DividerWithText,
  ErrorAlert,
  FormControlLabel,
} from "@lib/ui/core"
import { ReactComponent as FacebookLogo } from "@assets/images/Facebook.svg"
import { ReactComponent as GoogleLogo } from "@assets/images/Google.svg"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import {
  signup,
  googleLogin as googleLoginApi,
  facebookLogin as facebookLoginApi,
} from "@services/auth"

const FacebookButton = styled(Button)`
  border-radius: 30px;
  color: #000;
  background-color: #3b5998;
  &:hover {
    background-color: #3b5998;
  }
  text-transform: none;
  padding-left: 2rem;
  padding-right: 2rem;
`
const GoogleButton = styled(Button)`
  border-radius: 30px;
  background-color: #ffffff;
  &:hover {
    background-color: #ffffff;
  }
  text-transform: none;
  border: 1px solid #707070;
  padding-left: 2rem;
  padding-right: 2rem;
`

const LoginIcon = styled(SvgIcon)`
  margin-right: 0.5em;
  overflow: overlay;
`

const googleClientId =
  "702065624479-n7oeu4244c8nh5piv4p181i4qqqlcc45.apps.googleusercontent.com"

const facebookAppId = "302796200805953"

// eslint-disable-next-line arrow-body-style
const Register = ({ open, handleClose }) => {
  const { register, handleSubmit, errors, clearErrors, watch } = useForm({
    mode: "onTouched",
  })
  const [submitError, setSubmitError] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [checked, setChecked] = useState(false)

  const closeAlert = () => {
    setErrorAlert(false)
  }

  const updateAccessToken = useStoreActions(
    ({ localStorage }) => localStorage.auth.updateAccessToken
  )

  const googleLoginSuccess = async (res) => {
    try {
      const data = await googleLoginApi({ token: res.xc.access_token })
      if (data.auth) {
        updateAccessToken(data.auth.access_token)
        navigate("/subscription")
        handleClose()
      } else {
        setErrorAlert(true)
      }
    } catch (e) {
      console.log(e)
      setErrorAlert(true)
    }
  }

  const facebookLoginSuccess = async (res) => {
    try {
      const data = await facebookLoginApi({ token: res.accessToken })
      if (data.auth) {
        updateAccessToken(data.auth.access_token)
        navigate("/subscription")
        handleClose()
      } else {
        setErrorAlert(true)
      }
    } catch (e) {
      setErrorAlert(true)
    }
  }

  const socialLoginFailed = (e) => {
    setErrorAlert(true)
    console.log(e)
  }

  const onSubmit = async (values) => {
    const { data } = await signup({
      email: values.email,
      password: values.password,
    })
    if (!data.error) {
      updateAccessToken(data.auth.access_token)
      navigate("/subscription")
      handleClose()
    } else {
      setSubmitError(true)
    }
  }

  const { signIn: googleLogin } = useGoogleLogin({
    onSuccess: googleLoginSuccess,
    onFailure: socialLoginFailed,
    clientId: googleClientId,
    cookiePolicy: "single_host_origin",
  })

  return (
    <Box>
      <DialogWithCloseButton
        onClose={handleClose}
        open={open}
        title="新用戶註冊"
        subTitle="所有新用戶即可享有7天免費使用期"
        onExited={() => {
          clearErrors()
          setSubmitError(false)
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <TextField
              error={errors.email}
              inputRef={register({
                required: "請輸入電郵地址",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "請輸入正確的電郵地址",
                },
              })}
              name="email"
              label="電郵地址"
              helperText={errors.email?.message}
            />
          </Box>
          <Box p={1}>
            <TextField
              type="password"
              error={errors.password}
              inputRef={register({
                required: "請輸入密碼",

                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#$@!~%&*?]{8,}$/,
                  message:
                    "密碼必須至少包含八個字符、至少包含一個數字、小寫和大寫的字母",
                },
              })}
              name="password"
              label="密碼"
              helperText={errors.password?.message}
            />
          </Box>
          <Box p={1}>
            <TextField
              type="password"
              error={errors.confirmPassword}
              inputRef={register({
                required: "請再次輸入密碼",
                validate: (value) =>
                  value === watch("password") || "密碼兩次輸入不一樣",
              })}
              name="confirmPassword"
              label="確認密碼"
              helperText={errors.confirmPassword?.message}
            />
          </Box>
          <Box p={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked((prev) => !prev)}
                />
              }
              label={
                <Box fontSize="16px" display="flex">
                  我已閱讀並同意
                  <a href="/tnc" target="_blank">
                    <Box
                      color="#007bff"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      使用條款
                    </Box>
                  </a>
                </Box>
              }
            />
          </Box>
          <Box p={1}>
            <Button fullWidth py="1rem" type="submit" disabled={!checked}>
              <Typography color="textSecondary">註冊</Typography>
            </Button>
          </Box>
          {submitError && (
            <Box p={1} pt={0} color="#B70000">
              <Typography variant="footnote">電郵已被註冊</Typography>
            </Box>
          )}
        </form>
        <Box p={1}>
          <DividerWithText>或</DividerWithText>
        </Box>
        <Box p={1} display="flex" justifyContent="space-around" width="100%">
          <Box width="50%" pr={1}>
            <GoogleButton onClick={googleLogin} fullWidth>
              <LoginIcon component={GoogleLogo} fontSize="1.5rem" />
              <Typography color="textPrimary" variant="body2">
                使用Google登入
              </Typography>
            </GoogleButton>
          </Box>
          <Box width="50%" pl={1}>
            <FacebookLogin
              appId={facebookAppId}
              callback={facebookLoginSuccess}
              onFailure={socialLoginFailed}
              render={(renderProps) => (
                <FacebookButton onClick={renderProps.onClick} fullWidth>
                  <LoginIcon component={FacebookLogo} fontSize="1.5rem" />
                  <Typography color="textSecondary" variant="body2" noWrap>
                    使用Facebook登入
                  </Typography>
                </FacebookButton>
              )}
            />
          </Box>
        </Box>
      </DialogWithCloseButton>
      <ErrorAlert open={errorAlert} handleClose={closeAlert}>
        系統出錯，請稍後再試。
      </ErrorAlert>
    </Box>
  )
}

export default Register
