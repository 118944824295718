import React, { useState } from "react"
import { useStoreActions } from "easy-peasy"
import { navigate } from "@reach/router"
import { useGoogleLogin } from "react-google-login"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import {
  Box,
  DialogWithCloseButton,
  TextField,
  Button,
  SvgIcon,
  Typography,
  DividerWithText,
  ErrorAlert,
} from "@lib/ui/core"
import { ReactComponent as FacebookLogo } from "@assets/images/Facebook.svg"
import { ReactComponent as GoogleLogo } from "@assets/images/Google.svg"

import { useForm } from "react-hook-form"
import styled from "styled-components"
import {
  login,
  googleLogin as googleLoginApi,
  facebookLogin as facebookLoginApi,
} from "@services/auth"

const FacebookButton = styled(Button)`
  border-radius: 30px;
  color: #000;
  background-color: #3b5998;
  &:hover {
    background-color: #3b5998;
  }
  text-transform: none;
  padding-left: 2rem;
  padding-right: 2rem;
`
const GoogleButton = styled(Button)`
  border-radius: 30px;
  background-color: #ffffff;
  &:hover {
    background-color: #ffffff;
  }
  text-transform: none;
  border: 1px solid #707070;
  padding-left: 2rem;
  padding-right: 2rem;
`

const LoginIcon = styled(SvgIcon)`
  margin-right: 0.5em;
  overflow: overlay;
`

// import Menu from "../Menu"

const googleClientId =
  "702065624479-n7oeu4244c8nh5piv4p181i4qqqlcc45.apps.googleusercontent.com"

const facebookAppId = "302796200805953"

// eslint-disable-next-line arrow-body-style
const Login = ({
  open,
  handleClose,
  openRegister,
  openForget,
  subscription,
}) => {
  const { register, handleSubmit, errors, clearErrors } = useForm({
    mode: "onTouched",
  })

  const [submitError, setSubmitError] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)

  const closeAlert = () => {
    setErrorAlert(false)
  }

  const updateAccessToken = useStoreActions(
    ({ localStorage }) => localStorage.auth.updateAccessToken
  )

  const googleLoginSuccess = async (res) => {
    try {
      const find = Object.keys(res).find((key) =>
        Object.prototype.hasOwnProperty.call(res[key], "access_token")
      )
      const data = await googleLoginApi({ token: res[find].access_token })
      if (data.auth) {
        updateAccessToken(data.auth.access_token)
        if (
          !subscription &&
          (data.item.is_subscriber || data.item.trial_quota)
        ) {
          navigate("/exercise/select")
        } else {
          navigate("/subscription")
        }
      } else {
        setErrorAlert(true)
      }
    } catch (e) {
      console.log(e)
      setErrorAlert(true)
    } finally {
      handleClose()
    }
  }

  const facebookLoginSuccess = async (res) => {
    try {
      const data = await facebookLoginApi({ token: res.accessToken })
      if (data.auth) {
        updateAccessToken(data.auth.access_token)
        if (
          !subscription &&
          (data.item.is_subscriber || data.item.trial_quota)
        ) {
          navigate("/exercise/select")
        } else {
          navigate("/subscription")
        }
      } else {
        setErrorAlert(true)
      }
    } catch (e) {
      setErrorAlert(true)
    } finally {
      handleClose()
    }
  }

  const socialLoginFailed = (e) => {
    setErrorAlert(true)
    console.log(e)
  }

  const onSubmit = async (values) => {
    setSubmitError(false)
    const { data } = await login(values)
    if (!data.error) {
      updateAccessToken(data.auth.access_token)
      if (!subscription && (data.item.is_subscriber || data.item.trial_quota)) {
        navigate("/exercise/select")
      } else {
        navigate("/subscription")
      }
      handleClose()
    } else {
      setSubmitError(true)
    }
  }
  const { signIn: googleLogin } = useGoogleLogin({
    onSuccess: googleLoginSuccess,
    onFailure: socialLoginFailed,
    clientId: googleClientId,
    cookiePolicy: "single_host_origin",
  })
  return (
    <Box>
      <DialogWithCloseButton
        onClose={handleClose}
        open={open}
        title="登入Mathod"
        subTitle={
          <Box display="flex" color="#4D4D4D">
            <Typography variant="footnote">你是新用戶嗎?</Typography>
            <Box
              pl={0.5}
              color="#007BFF"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose()
                openRegister()
              }}
            >
              <Typography variant="footnote">建立帳戶</Typography>
            </Box>
          </Box>
        }
        onExited={() => {
          setSubmitError(false)
          clearErrors()
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <TextField
              error={errors.email}
              inputRef={register({
                required: "請輸入電郵地址",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "請輸入正確的電郵地址",
                },
              })}
              name="email"
              label="電郵地址"
              helperText={errors.email?.message}
            />
          </Box>
          <Box p={1}>
            <TextField
              type="password"
              error={errors.password}
              inputRef={register({
                required: "請輸入密碼",
              })}
              name="password"
              label="密碼"
              helperText={errors.password?.message}
            />
          </Box>
          <Box p={1}>
            <Button fullWidth py="1rem" type="submit">
              <Typography color="textSecondary">登入</Typography>
            </Button>
          </Box>
          {submitError && (
            <Box p={1} pt={0} color="#B70000">
              <Typography variant="footnote">電郵或密碼錯誤</Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end" width="100%" p={1}>
            <Box
              pl={0.5}
              color="#007BFF"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose()
                openForget()
              }}
            >
              <Typography variant="footnote">忘記密碼</Typography>
            </Box>
            {/* <Link
              onClick={() => {
                handleClose()
                openForget()
              }}
            >
              忘記密碼
            </Link> */}
          </Box>
        </form>
        <Box p={1}>
          <DividerWithText>或</DividerWithText>
        </Box>
        <Box p={1} display="flex" justifyContent="space-around" width="100%">
          <Box width="50%" pr={1}>
            <GoogleButton onClick={googleLogin} fullWidth>
              <LoginIcon component={GoogleLogo} fontSize="1.5rem" />
              <Typography color="textPrimary" variant="body2">
                使用Google登入
              </Typography>
            </GoogleButton>
          </Box>
          <Box width="50%" pl={1}>
            <FacebookLogin
              appId={facebookAppId}
              callback={facebookLoginSuccess}
              onFailure={socialLoginFailed}
              render={(renderProps) => (
                <FacebookButton onClick={renderProps.onClick} fullWidth>
                  <LoginIcon component={FacebookLogo} fontSize="1.5rem" />
                  <Typography color="textSecondary" variant="body2" noWrap>
                    使用Facebook登入
                  </Typography>
                </FacebookButton>
              )}
            />
          </Box>
        </Box>
      </DialogWithCloseButton>
      <ErrorAlert open={errorAlert} handleClose={closeAlert}>
        系統出錯，請稍後再試。
      </ErrorAlert>
    </Box>
  )
}

export default Login
