import React from "react"
import styled from "styled-components"

import Link from "@components/Link"
import Container from "@layouts/Container"
import Box from "@lib/ui/core/Box"
import Typography from "@lib/ui/core/Typography"
import { SvgIcon } from "@lib/ui/core"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"

const StyledList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Footer = () => {
  const today = new Date()
  return (
    <Box
      component="footer"
      bgcolor="background.footer"
      color="info.contrastText"
      py={2.5}
    >
      <Container>
        <Box display="flex" mb={2.5}>
          <Box flexGrow={1}>
            <Box justifyContent="center" display="flex" component={StyledList}>
              <Box mr={2} component="li">
                <Link to="/qna">
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    常見問題
                  </Typography>
                </Link>
              </Box>
              <Box mr={2} component="li">
                <Link to="/contact">
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    聯絡我們
                  </Typography>
                </Link>
              </Box>
              <Box mr={2} component="li">
                <Link to="/tnc">
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    使用條款
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box justifyContent="center" display="flex">
          <Box mr={2}>
            <Typography variant="body2" color="inherit">
              ©{today.getFullYear()} Mathod
            </Typography>
          </Box>
          <Box mr={2}>
            <Link
              href="https://www.facebook.com/mathodacademy/"
              target="_blank"
            >
              <SvgIcon component={FacebookIcon} color="white" />
            </Link>
          </Box>
          <Box>
            <Link
              href="https://www.instagram.com/mathod_official/"
              target="_blank"
            >
              <SvgIcon component={InstagramIcon} color="white" />
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
