import React, { useState } from "react"
import {
  Box,
  DialogWithCloseButton,
  TextField,
  Button,
  Typography,
  Link,
} from "@lib/ui/core"

import { useForm } from "react-hook-form"

import { forgetPassword } from "@services/auth"

// import Menu from "../Menu"

// eslint-disable-next-line arrow-body-style
const ForgetPassword = ({ open, handleClose, openLogin }) => {
  const { register, handleSubmit, errors, clearErrors } = useForm({
    mode: "onTouched",
  })
  const [submitted, setSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  //TODO: Add Submit Form Function
  const onSubmit = async (values) => {
    const { data } = await forgetPassword(values)
    setSubmitError(false)
    if (data.error) {
      setSubmitError(true)
    } else {
      setSubmitted(true)
    }
  }

  return (
    <Box>
      <DialogWithCloseButton
        onClose={handleClose}
        open={open}
        title="忘記密碼"
        subTitle="請輸入你的電子郵件地址以重設密碼"
        onExited={() => {
          clearErrors()
          setSubmitted(false)
        }}
      >
        {!submitted ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={1}>
              <TextField
                error={errors.email}
                inputRef={register({
                  required: "請輸入電郵地址",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "請輸入正確的電郵地址",
                  },
                })}
                name="email"
                label="電郵地址"
                helperText={errors.email?.message}
              />
            </Box>

            <Box p={1} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  color="default"
                  onClick={() => {
                    handleClose()
                    openLogin()
                  }}
                >
                  <Typography color="textSecondary">取消</Typography>
                </Button>
              </Box>
              <Box>
                <Button type="submit">
                  <Typography color="textSecondary">遞交</Typography>
                </Button>
              </Box>
            </Box>
          </form>
        ) : (
          <Box p={1}>
            <Typography variant="body1">重設密碼電郵已傳送。</Typography>
          </Box>
        )}
      </DialogWithCloseButton>
    </Box>
  )
}

export default ForgetPassword
