import React from "react"
import MuiCard from "@material-ui/core/Card"
import styled from "styled-components"
import { sizing, spacing, borders } from "@material-ui/system"

const Card = styled(({ ...props }) => <MuiCard {...props} />)`
  max-width: 250px;
  max-height: 500px;
  width: 100%;
  height: 100%;
  border-radius: 19px;
`

export default Card
