import React from "react"
import styled from "styled-components"
import MuiLink from "@material-ui/core/Link"
import { palette, typography } from "@material-ui/system"

const Link = styled(({ color, ...props }) => <MuiLink {...props} />)`
  ${palette}
  ${typography}
  cursor:pointer;
`

Link.defaultProps = {
  color: "actionText.active",
  underline: "none",
  variant: "body1",
}
export default Link
