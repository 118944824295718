import React, { useState, useEffect, useRef } from "react"
import { withStyles } from "@material-ui/core/styles"
import { useStoreState, useStoreActions } from "easy-peasy"
import { navigate, useLocation } from "@reach/router"
import qs from "qs"
import { Link as ScrollLink } from "react-scroll"
import Imgix from "react-imgix"
import styled from "styled-components"
import Portal from "@material-ui/core/Portal"

import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Hidden,
  Paper,
  Container,
  Snackbar,
  Alert,
} from "@lib/ui/core"
import Link from "@components/Link"
import Logo from "@assets/images/mathodlogo.png"

import { getProfile } from "@services/user"

import Login from "@components/Login"
import Register from "@components/Register"
import ForgetPassword from "@components/ForgetPassword"

const SignUpButton = styled(Button)`
  border-radius: 30px;

  &:hover {
    background-image: linear-gradient(to right, #7b00ff, #007bff);
  }
`

// eslint-disable-next-line arrow-body-style
const HomepageHeader = () => {
  const accessToken = useStoreState(
    ({ localStorage }) => localStorage.auth.accessToken
  )

  const [loginOpen, setLoginOpen] = useState(false)
  const [signUpOpen, setSignUpOpen] = useState(false)
  const [forgetPwOpen, setForgetPwOpen] = useState(false)
  const [verifySuccess, setVerifySuccess] = useState(false)

  const redirectRegister = () => {
    setSignUpOpen(true)
  }
  const redirectForgetPw = () => {
    setForgetPwOpen(true)
  }
  const redirectLogin = () => {
    setLoginOpen(true)
  }

  const location = useLocation()

  useEffect(() => {
    const paramString = location.search.substring(1)
    const searchParams = qs.parse(paramString)
    if (searchParams.verify_success) {
      setVerifySuccess(true)
      setLoginOpen(true)
    }
  }, [location])

  return (
    <AppBar bgcolor="background.header" position="sticky" elevation={12}>
      <Toolbar py={1.5}>
        <Container>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            position="relative"
          >
            <Box display="flex" alignItems="center" zIndex={2}>
              <Link to="/">
                <Box fontSize="1.75rem" height="1.75em" width="auto" clone>
                  <Imgix src={Logo} />
                </Box>
              </Link>
            </Box>
            <Hidden smDown>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                position="absolute"
                zIndex={1}
              >
                <ScrollLink
                  to="whatismethod"
                  spy
                  smooth
                  duration={500}
                  onClick={() => location.pathname !== "/" && navigate("/")}
                >
                  <Box
                    pr={5}
                    style={{ cursor: "pointer" }}
                    fontSize="1rem"
                    color="#fff"
                  >
                    甚麼是Mathod?
                  </Box>
                </ScrollLink>
                <ScrollLink
                  to="concept"
                  spy
                  smooth
                  duration={500}
                  onClick={() => location.pathname !== "/" && navigate("/")}
                >
                  <Box
                    pr={5}
                    style={{ cursor: "pointer" }}
                    fontSize="1rem"
                    color="#fff"
                  >
                    理念願景
                  </Box>
                </ScrollLink>
                <Link to="/resources">
                  <Box pr={5} fontSize="1rem" color="#fff">
                    資源下載
                  </Box>
                </Link>
              </Box>
            </Hidden>
            <Box
              display="flex"
              width="100%"
              position="absolute"
              justifyContent="flex-end"
              whiteSpace="nowrap"
            >
              <Box zIndex={2} display="flex" alignItems="center">
                <SignUpButton
                  size="small"
                  px="2rem"
                  onClick={() => {
                    setSignUpOpen(true)
                  }}
                >
                  <Box color="#fff" fontSize="1rem">
                    立即試用
                  </Box>
                </SignUpButton>

                {/* <Button
                  variant="text"
                  onClick={() => {
                    if (accessToken) {
                      navigate("/exercise/select")
                    } else {
                      setLoginOpen(true)
                    }
                  }}
                > */}
                <Box
                  ml={5}
                  color="#fff"
                  fontSize="1rem"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (accessToken) {
                      navigate("/exercise/select")
                    } else {
                      setLoginOpen(true)
                    }
                  }}
                >
                  登入
                </Box>
                {/* </Button> */}
              </Box>
            </Box>
          </Box>
          <ForgetPassword
            open={forgetPwOpen}
            handleClose={() => setForgetPwOpen(false)}
            openLogin={redirectLogin}
          />
          <Register
            open={signUpOpen}
            handleClose={() => setSignUpOpen(false)}
          />
          <Login
            open={loginOpen}
            handleClose={() => setLoginOpen(false)}
            openRegister={redirectRegister}
            openForget={redirectForgetPw}
          />
          <Portal>
            <Snackbar
              open={verifySuccess}
              autoHideDuration={6000}
              onClose={() => setVerifySuccess(false)}
            >
              <Alert onClose={() => setVerifySuccess(false)} severity="success">
                電郵驗證成功，請登入
              </Alert>
            </Snackbar>
          </Portal>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default HomepageHeader
