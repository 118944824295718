import React from "react"
import styled from "styled-components"
import MuiButton from "@material-ui/core/Button"
import { sizing, spacing, borders } from "@material-ui/system"

const Button = styled(
  ({ width, maxWidth, minWidth, px, py, borderRadius, ...props }) => (
    <MuiButton {...props} />
  )
)`
  ${spacing}
  ${borders}
  ${sizing}
`

Button.defaultProps = {
  px: "3rem",
  py: ".5rem",
  color: "primary",
  variant: "contained",
  disableElevation: true,
}
export default Button
