import React from "react"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core"
import MuiTextField from "@material-ui/core/TextField"
import { sizing, spacing, borders } from "@material-ui/system"

const CustomTextField = styled(MuiTextField)``

const TextField = styled(({ ...props }) => <MuiTextField {...props} />)`
  ${sizing}
  ${spacing}

  border-radius: 4px;

  .MuiInputBase-input {
    font-size: 16px;
  }
  .MuiFormLabel-root {
    color: rgb(77, 77, 77);
    font-size: 18px;
  }
  .MuiFilledInput-root {
    background-color: rgb(230, 230, 230);
  }
  .MuiFormHelperText-root {
    font-size: 0.875rem;
    margin-left: 0px !important;
  }
  .MuiFormHelperText-root.Mui-error {
    color: rgb(183, 0, 0);
  }
`

TextField.defaultProps = {
  variant: "filled",
  fullWidth: true,
  size: "medium",
  InputProps: { disableUnderline: true },
}

export default TextField
